<template>
  <div id="promoter" class="select-main">
    <div class="div-main">
      <loading :loading="loading" :text="loadingText"></loading>
      <el-form id="formSearch" ref="form" label-width="100px" style="border-bottom: 1px solid #f6f6f6; margin-bottom: 5px; margin-top: 5px;">
        <el-form-item label="订单号" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number"  v-model="orderNo" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="手机号" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number" v-model="username" placeholder="请输入" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="分销员" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input type="number" v-model="sharerUsername" placeholder="请输入分销员手机号" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="status" placeholder="请选择">
            <el-option
                v-for="status in statuses"
                :key="status.id"
                :label="status.name"
                :value="status.id"
                >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="type" placeholder="请选择">
            <el-option :key="orderTypeEnum.PU_TONG"       label="普通订单"    :value="orderTypeEnum.PU_TONG"></el-option>
            <el-option :key="orderTypeEnum.PIN_TUAN"      label="拼团订单"    :value="orderTypeEnum.PIN_TUAN"></el-option>
            <el-option :key="orderTypeEnum.JI_FEN"        label="积分订单"    :value="orderTypeEnum.JI_FEN"></el-option>
            <el-option :key="orderTypeEnum.DISTRIBUTION"  label="分销订单"    :value="orderTypeEnum.DISTRIBUTION"></el-option>
            <el-option :key="orderTypeEnum.UNIVERSITY"    label="院校调剂包"  :value="orderTypeEnum.UNIVERSITY"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="购买商品" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-input @keyup.native="productIdOnChange()" v-on:blur="productIdOnBlur()" type="number" v-model="productId" placeholder="请输入ID" style="width: 200px"></el-input>
          <span  class="product-tip" v-show="productName == '' ? false : true">{{ productName }}</span>
        </el-form-item>
        <el-form-item label="是否免费" style="width: 300px; margin-bottom:10px;display: inline-block">
          <el-select v-model="isFree"  placeholder="请选择">
            <el-option :key="true"  label="是" :value="true" ></el-option>
            <el-option :key="false"  label="否" :value="false" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单日期" style="width: 480px; margin-bottom:10px;display: inline-block">
          <el-date-picker
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <div style="display:inline-block;margin-left: 60px">
          <el-button type="primary" @click="getData">搜索</el-button>
          <el-button type="warning" @click="reset">重置</el-button>
        </div>
      </el-form>
      <div id="divList">

        <el-table id="tblList" border :data="orders"  style="width: 100%" v-show="tableShow" header-row-class-name="table-header">
          <el-table-column prop="orderNo" label="订单号" align="center" width="180"></el-table-column>
          <el-table-column prop="studentName" label="学员" align="center" width="120"></el-table-column>
          <el-table-column prop="courseName" label="商品名称" align="left"></el-table-column>
          <el-table-column prop="totalAmount" label="金额" align="center" width="90"></el-table-column>
          <el-table-column prop="paymentType" label="付款方式" align="center" width="100"></el-table-column>
          <el-table-column prop="expressNo" label="快递单号" align="center" width="100"></el-table-column>
          <el-table-column prop="statusName" label="状态" align="center" width="100"></el-table-column>
          <el-table-column prop="typeName" label="类型" align="center" width="100"></el-table-column>
          <el-table-column prop="promoterName" label="分销员" align="center" width="100"></el-table-column>
          <el-table-column prop="createdOn" label="创建日期" align="center" width="180"></el-table-column>
          <el-table-column prop="modifiedOn" label="更新日期" align="center" width="180"></el-table-column>
          <el-table-column fixed="right" label="操作" width="150" align="left">
            <template slot-scope="scope">
              <el-button @click="handleClickDetail(scope.row)" type="text" size="small">详情</el-button>
              <el-button @click="handleClickPayment(scope.row)" type="text" size="small" v-show="scope.row.status == orderStatusEnum.DZF">收款</el-button>
              <el-button @click="handleClickInvoice(scope.row)" type="text" size="small" v-show="scope.row.status == orderStatusEnum.DFH">发货</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page="page" :size="size" @pageChange="handleCurrentChange" @sizeChange="handleSizeChange"></pagination>
      </div>
    </div>
    <edit-dialog ref="editDialog"></edit-dialog>
  </div>
</template>

<script>
import Loading from "../../components/Loading";
import {UrlEnum,POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE,getLocalProjectId,OrderStatusEnum,OrderTypeEnum} from "../../../public/js/common-vue";
import {SESSON_KEY_GLOBAL_MODULEID} from "../../../public/js/sesson-key-vue";
import Pagination from "../../components/Pagination";
import EditDialog from "../../components/EditDialog";
export default {
  name: "OrderList",
  data() {
    return {
      loading: false,
      loadingText: "加载中",
      tableShow: false,
      orderNo: '',
      username: '',
      total: 0,
      page: 1,
      size: 20,
      status:'',
      productId:'',
      productName:'',
      isFree:'',
      dateTime: '',
      isShowOpenBtn:false,
      isShowCloseBtn:false,
      orderStatusEnum:OrderStatusEnum,
      orderTypeEnum:OrderTypeEnum,
      statuses: [],
      promoters: [],
      orders:[],
      type:'',
      sharerUsername:''
    }
  },
  methods:{
    productIdOnChange(){
      console.log('onChange')
      if(this.productId == ''){
        this.productName = '';
      }
      else{
        this.getCourseById(this.productId);
      }
    },
    productIdOnBlur(){
      console.log('onBlur');
      this.productName='';
    },

    handleClickDetail(row){
      const url = 'detail.html?id=' + row.id;
      this.$refs.editDialog.init('detail',POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE,'订单详情',url);
    },
    handleClickPayment(row){
      const url = "../finance/payment/add.html?orderNo=" + row.orderNo + "&totalAmount=" + row.totalAmount;
      this.$refs.editDialog.init('edit',POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE,'添加收款单',url);
    },
    handleClickInvoice(row){
      const url = "invoce/add.html?orderNo="+row.orderNo;
      this.$refs.editDialog.init('edit',POP_WIDTH_MIDDLE,POP_HEIGHT_MIDDLE, "添加发货单",url);
    },

    handleCheckIsShowCloseBtn(row) {
      if(
          (row.type == OrderTypeEnum.PIN_TUAN || row.type == OrderTypeEnum.PU_TONG) &&
          (row.status == OrderStatusEnum.DFH || row.status == OrderStatusEnum.YFH || row.status == OrderStatusEnum.THSQ || row.status == OrderStatusEnum.BFTK || row.status == OrderStatusEnum.YPJ) &&
          this.isShowCloseBtn){
        return true;
      }
      else{
        return false;
      }
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getData();
    },
    handleSizeChange(size) {
      this.size = size;
      this.getData();
    },
    makeUrl() {
      let url = UrlEnum.ORDER;
      url += "?page="+this.page+"&size="+this.size+"&projectId="+getLocalProjectId();
      if(this.orderNo != ''){
        url += "&orderNo="+this.orderNo;
      }
      if(this.username != ''){
        url += "&username="+this.username;
      }
      if(this.status != ''){
        url += "&status="+this.status;
      }
      if(this.dateTime != ''){
        let startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
        let endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
        url += "&startTime="+startTime+"&endTime="+endTime;
      }
      if(this.productId != ''){
        url += "&productId="+this.productId;
      }
      if(this.isFree !== ''){
        url += "&isFree="+this.isFree;
      }
      if(this.type != ''){
        url += "&type="+this.type;
      }
      if(this.sharerUsername != ''){
        url += "&type="+OrderTypeEnum.DISTRIBUTION+"&sharerUsername="+this.sharerUsername;
      }
      return url;
    },
    getData(){
      this.loading=true;
      const url = this.makeUrl();
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.total = res.data.total;
        this.orders = res.data.list;
        this.tableShow = true;
        //请求成功后
        if(this.isShowOpenBtn){
          $("#btnAdd").show();
        }
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow=true;
      })
    },
    getStatus(){
      this.loading = true;
      const url = UrlEnum.ORDER_STATUS;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.statuses =  res.data.list;
      })
      .catch((res) => {
        this.loading = false;
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
        this.tableShow = true;
      })
    },
    reset(){
      this.dateTime = '';
      this.isFree = '';
      this.productName='';
      this.productId='';
      this.status = '';
      this.username = '';
      this.orderNo = '';
      this.type = '';
      this.sharerUsername = '';
      this.page=1;
      this.getData();
    },
    getPermission(){
      const moduleIds = localStorage.getItem(SESSON_KEY_GLOBAL_MODULEID);
    },
    getCourseById(courseId){
      const url = UrlEnum.COURSE + "/" + courseId;
      this.$http({
        method: "get",
        url: url,
        data: '',
      })
      .then((res) => {
        if(res.data.name != undefined){
          this.productName = res.data.name;
        }
      })
      .catch((res) => {
        if (res.response != null) {
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      })
    }
  },
  components:{Loading,UrlEnum,Pagination,EditDialog},
  mounted() {
    this.getData();
    this.getStatus();
    this.getPermission();
    const that = this;
    window.addEventListener('message', function (e) {
      if(e.data.key == "refresh"){
        that.$refs.editDialog.hide();
        that.getData();
      }
    }, false)
  }
}
</script>

<style>
.div-main{
  background: white;
  padding:10px 0px;
  height: 100%;
  overflow: auto;
  margin:15px;
}
.select-main{
  height: 100%;
}
.table-header th {
  background-color: #f6f6f6 !important;
}
.el-table td {
  padding: 6px 0 !important;
}
#divList{
  margin:10px;
}
.opt{
  height: 40px;
}
.product-tip{
  position: absolute;
  width: 178px;
  z-index: 1;
  background: white;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  padding: 10px;
  color: #666;
  margin-top: 40px;
  margin-left: -200px;
  line-height: 25px;
}
</style>
